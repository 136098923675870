
@font-face {
  font-family: 'MyCustomFont';
  src: url('/src/css/YuPearl-Medium.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  height: 100%;
}

.fixed-image {
  width: 30%;
  height: 30%;
  object-fit: contain;
}


h2 {
  font-family: 'MyCustomFont', sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  color: #333;
  letter-spacing: 2px;
  line-height: 1.5;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);  /* 陰影效果 */
  border-bottom: 4px solid #f0c14b;  /* 底部裝飾線 */
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h3 {
  font-family: 'MyCustomFont', serif;
  font-weight: 700;
  font-size: 2rem;
  color: #444;
  letter-spacing: 1.5px;
  line-height: 1.4;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);  /* 輕微陰影 */
  background-color: #f9f9f9;  /* 背景色 */
  padding: 10px 15px;
  border-left: 5px solid #2196F3;  /* 左側裝飾線 */
  margin-bottom: 20px;
}

h4 {
  font-family: 'MyCustomFont', sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  color: #555;
  letter-spacing: 1px;
  line-height: 1.3;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);  /* 輕微陰影 */
  background-color: #f0f0f0;  /* 背景色 */
  padding: 8px 12px;
  border-radius: 5px;  /* 圓角效果 */
  margin-bottom: 15px;
}

h5 {
  font-family: 'MyCustomFont', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  color: #666;
  letter-spacing: 0.5px;
  line-height: 1.2;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);  /* 輕微陰影 */
  border-top: 2px dashed #ccc;  /* 上方裝飾線 */
  padding-top: 10px;
  margin-top: 20px;
}
