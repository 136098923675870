.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-preview {
    position: relative;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    width: 180px; /* 增大30px */
}

.image-preview img {
    width: 150px; /* 增大30px */
    height: 150px; /* 增大30px */
    object-fit: cover;
    margin-bottom: 5px;
}

.image-preview p {
    margin: 0;
    font-size: 12px;
    color: #666;
}

.image-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* form.css */
.quill-editor-container {
    min-height: 300px; /* 使編輯器至少 300px 高 */
    max-height: 500px; /* 設置最大高度，防止它過高 */
    overflow-y: auto;  /* 當內容超過高度時顯示滾動條 */
    margin-bottom: 20px; /* 保證編輯器底部有足夠空間 */
}
