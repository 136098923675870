/* 自定義 Modal 樣式 */
.custom-modal-content {
    background: transparent; /* 移除白色背景 */
    border: none; /* 移除邊框 */
    box-shadow: none; /* 移除陰影 */
  }

  .custom-modal-body {
    padding: 0; /* 移除內邊距 */
  }

  /* 關閉按鈕 */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1051; /* 確保在 Modal 上層 */
  }

  .close-btn:hover {
    color: #ff4d4f; /* Hover 狀態顏色 */
  }
